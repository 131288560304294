<template>
  <div v-if="loadingFoodie" class="custom-loading">
    <a-loader color="text-gray h-1 w-1"/>
  </div>
  <div v-else>
    <Header :values="values" :header="header"></Header>
    <div class="pt-16 pb-14">
      <div class="p-normal border-b border-gray">
        <div class="flex">
          <div class="flex-auto flex flex-col text-left">
            <a-initials :name="values.name" :src="values.profile_picture" :desing-class="'custom-width'"></a-initials>

            <div class="flex-1 flex flex-col text-left py-small">
              <!--            <p class="text-xl font-normal">{{ values.name }}</p>-->
              <!--            <p v-if="values?.ig_username" class="font-light">-->
              <span class="flex items-center text-xl">
                <i @click="markAsFavorite" class="pr-2" :class="markFavorite ? 'icon-heart text-red-400' : 'icon-heartEmpty'"></i>
                <a :href="`https://instagram.com/${values?.ig_username}`" class="text-lg" target="_blank">{{ values?.name }}</a>
              </span>
              <span v-if="values?.ig_username" class="flex items-center">
                <i class="icon-instagram text-xl pr-2"></i>
                <a :href="`https://instagram.com/${values?.ig_username}`" class="text-lg text-primary" target="_blank">{{ ` @${values?.ig_username}` }}</a>
              </span>
            </div>
            <div class="flex-1">
              <p class="text-gray pr-3 align-bottom">{{ values?.biography }}</p>
            </div>
          </div>
          <div class="flex-none flex flex-col text-center space-b justify-between">
            <div class="block border-b border-gray">
              <h4 class="text-xl">{{ values.ig_followers_count ? formattedFollowers(values.ig_followers_count) : 0 }}</h4>
              <h4 class="text-gray">Followers</h4>
            </div>
            <div class="block border-b border-gray">
              <h4 class="text-xl">{{ values.completed_collabs_count ? values.completed_collabs_count : 0 }}</h4>
              <h4 class="text-gray">Colabs</h4>
              <!-- sqdqsd -->
            </div>
            <div class="block">
              <h4 class="text-xl">{{ values.ig_engagement_prc ? values.ig_engagement_prc : 0 }}%</h4>
              <h4 class="text-gray">Engagement</h4>
            </div>
            <button @click="singleCollaboration" :class="values.is_premium && products.length > 0 ? 'bg-yellow_ribbon text-onboarding_gray' : 'bg-primary' " class="block font-bold border rounded-full w-full mt-2 py-2 px-8 uppercase font-medium text-white">
              <i v-if="values.is_premium && products.length > 0" class="pi pi-star" style="font-size: 12px !important;"></i>
              colab
            </button>
          </div>
        </div>
      </div>
      <div class="p-normal flex flex-col justify-center mt-4">
        <div v-if="values.is_premium && products.length > 0" class="flex flex-col items-center">
          <div v-for="product in products" :key="product" class="flex border rounded-md py-4 px-4 justify-between mb-2 w-full xl:w-2/4">
            <div class="text-left mr-2 text-sm lg:text-base">{{ product.description }}</div>
            <div class="bold min-w-8 text-right" style="min-width: 3.5rem; font-weight: 600;">{{ product.price_e2 / 100 + '€' }}</div>
          </div>
        </div>
        <div v-else class="btn__container">
          <a :href="`https://instagram.com/${values.ig_username}`" class="btn" target="_blank">
            <i class="fab fa-instagram"></i>
            <span class="text-xl">Ver en instagram</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex'
import Header from '@/components/authenticated/Header'
// import axios from 'axios'

export default {
  name: 'Show',
  components: {
    Header
  },
  data () {
    return {
      values: [],
      igImages: [],
      header: {
        type: 'explore.show'
      },
      body: {
        attach: [],
        detach: []
      },
      markFavorite: false,
      letFavorite: false,
      loadingFoodie: true,
      loadingRestaurant: true,
      FBConnected: false,
      products: []
    }
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'session/isAuthenticated'
    }),
    ...mapState({
      user: ({ session }) => session.user
    })
  },
  methods: {
    ...mapActions({
      showFoodie: 'foodies/show',
      showRestaurant: 'restaurants/show'
    }),
    formattedFollowers (followers) {
      if (followers >= 1000000) {
        return parseFloat(followers / 1000000).toFixed(1) + 'M'
      } else if (followers >= 1100) {
        return parseFloat(followers / 1000).toFixed(1) + 'k'
      }
      return followers
    },
    getFoodieProfile () {
      this.$repository.foodies
        .show(this.$route.params.id, { with: 'user', with_count: 'completedCollabs' })
        .then(({ data: response }) => {
          this.values = response
          this.markFavorite = this.values.favorite
          this.letFavorite = this.values.favorite
        })
        .catch(() => {
          this.$router.push({ name: 'Explore' })
        })
        //   window.FB.getLoginStatus((response) => {
        //     if (response.status === 'connected') {
        //       const route = `https://graph.facebook.com/v11.0/${this.user.ig_user_id}?fields=business_discovery.username(${this.values.ig_username}){followers_count,media_count,media{media_type,media_url}}&access_token=${response.authResponse.accessToken}`
        //       axios
        //         .get(route)
        //         .then(({ data }) => {
        //           this.igImages = data.business_discovery.media.data.filter(item => item.media_type === 'IMAGE')
        //         })
        //         .catch((error) => (console.log(error)))
        //
        //     } else {
        //       this.FBConnected = false
        //       console.log('disconected:', response)
        //     }
        //   })
      this.$repository.foodies.products(this.$route.params.id)
        .index()
        .then(({ data }) => {
          this.products = data.data
        })
        .finally(() => {
          this.loadingFoodie = !this.loadingFoodie
        })
    },
    getRestaurantProfile () {
      this.$repository.restaurants
        .show(this.$route.params.id, { with: 'user' })
        .then(({ data: response }) => {
          this.values = response
          this.markFavorite = this.values.favorite
        })
        .catch(() => {
          this.$router.push({ name: 'Explore' })
        })
        // .finally(() => {
        //   window.FB.getLoginStatus((response) => {
        //     if (response.status === 'connected') {
        //       this.FBConnected = true
        //       axios
        //         // .get(`https://graph.facebook.com/${response.authResponse.userID}/accounts?access_token=${response.authResponse.accessToken}`)
        //         .get(`https://graph.facebook.com/v3.2/${response.authResponse.userID}?fields=business_discovery.username(${this.values.ig_username}){followers_count,media_count,media,media_url}&access_token=${response.authResponse.accessToken}`)
        //         .then((request) => {
        //           console.log(request)
        //         })
        //         .catch((error) => (console.log(error)))
        //       // this.axios.get(`https://graph.facebook.com/`)
        //       // console.log('connected:', response)
        //       //   .then((resolve) => (console.log(resolve)))
        //     } else {
        //       this.FBConnected = false
        //       console.log('disconected:', response)
        //     }
        //   })
        // })
        .finally(() => {
          // window.FB.getLoginStatus((response) => {
          //   if (response.status === 'connected') {
          //     axios
          //       .get(`https://graph.facebook.com/v11.0/${this.values.user.ig_user_id}?fields=business_discovery.username(${this.values.ig_username}){followers_count,media_count,media{media_type,media_url}}&access_token=${response.authResponse.accessToken}`)
          //       .then(({ data }) => {
          //         this.igImages = data.business_discovery.media.data.filter(item => item.media_type === 'IMAGE')
          //       })
          //       .catch((error) => (console.log(error)))
          //     //       })
          //     //       .catch((error) => (console.log('request #2: ', error)))
          //     //     // })
          //     //     // .catch((error) => (console.log('request #2: ', error)))
          //     //   })
          //     //   .catch((error) => (console.log(error)))
          //   } else {
          //     this.FBConnected = false
          //     console.log('disconected:', response)
          //   }
          // })
          this.loadingFoodie = !this.loadingFoodie
        })
      this.markFavorite = this.values.favorite
      this.letFavorite = this.values.favorite
    },
    getProfileValues () {
      this.user.isFoodie ? this.getRestaurantProfile() : this.getFoodieProfile()
    },
    singleCollaboration () {
      if (this.values.is_premium && this.products.length > 0) {
        this.$router.replace({ name: 'collaboration.create.premium', params: { id: this.$route.params.id } })
      } else {
        this.$router.replace({ path: `/collaboration/create/${this.$route.params.id}` })
      }
      // this.$router.replace({ path: `/steps/create/${this.$route.params.id}` })
      // console.log('on construction')
    },
    markAsFavorite () {
      this.markFavorite = !this.markFavorite
      if (this.user.isRestaurant) {
        this.body.attach = []
        this.body.detach = []
        if (!this.letFavorite) {
          this.body.attach.push(this.values.id)
        } else {
          this.body.detach.push(this.values.id)
        }
        // console.log(this.letFavorite + ':' + this.body.attach + '/' + this.body.detach)
        this.letFavorite = !this.letFavorite
        return this.$repository.restaurants
          .setFavorite(this.user.restaurant.id, this.body)
      }
    }
    // image (event) {
    //   event.path[0].height = event.path[0].width
    // }
  },
  mounted () {
    window.scrollTo({ top: 0 })
  },
  created () {
    if (this.isAuthenticated) {
      this.getProfileValues()
    }
  }
}
</script>

<style lang="scss" scoped>
.user-profile-image {
  width: 80px !important;
  height: 80px !important;
  object-fit: cover;
}

.grid-button {
  padding: 10px 32px;
}

.make_galery {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 1em;
}

.make_galery{
  display: inline-block;
  width: calc( 100% - 5px);
  text-align: left;
  margin-left: 5px;
  .galery_grid{
    display:inline-block;
    width: calc(33% - 5px);
    position: relative;
    height: 0px;
    padding-top: 32.341%;
    background-size: cover !important;
    margin-right: 5px;
    background-position: 50% 50% !important;
  }
}
/*
.make_galery{
  display: inline-block;
  width: calc( 100% - 5px);
  text-align: left;
  margin-left: 5px;
  .galery_grid{
    display:inline-block;
    width: calc(33% - 5px);
    position: relative;
    height: 28vh;
    background-size: cover !important;
    margin-right: 5px;
    background-position: 50% 50% !important;
  }
}*/
.grid-gallery {
  display: grid;
  grid-template-columns: repeat(3, minmax(6rem, 1fr));
}

.grid-gallery > div {
  display: grid;
}

.grid-gallery > div::before {
  content: "";
  padding-bottom: 100%;
  display: block;
}

.grid-gallery > div::before,
.grid-gallery > div > img {
  grid-area: 1 / 1 / 1 / 1;
}

.grid-gallery > div > img {
  width: 100%;
  object-fit: cover;
  aspect-ratio: 1;
}
.custom-width{
  min-width: 72px;
  max-width: 72px;
  width: 72px;
  height: 72px;
}
/////
.btn__container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn {
  min-width: 110px;
  background: linear-gradient(to right, #ff3019 0%,#c90477 100%);
  padding: 1rem 2rem;
  text-decoration: none;
  color: #fff;
  display: flex;
  transition: all .2s ease-in-out;
  margin-right: 10px;

  i {
    color: #fff;
    font-size: 20px;
    padding-right: 10px;
    transition: all .3s ease-in-out;
  }
  span {
    font-family: "Roboto", sans-serif;
    align-self: center;
    transform: translateX(0px);
    transition: all .1s ease-in-out;
    opacity: 1;
  }
}
</style>
